<script lang="ts" setup>
import { CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import { EMAIL, supportPhone } from '~/constants/contact'
import { ROUTES } from '~/constants/routes'

const { t } = useI18n()

const config = useRuntimeConfig()

type FooterItem = {
  label: string
  href: string
  target?: string
}

type FooterItemGroup = {
  title?: string
  items: FooterItem[]
  target?: string
}

const footerItemGroups = computed<FooterItemGroup[]>(() => [
  {
    title: t('common.siteName'),
    items: [
      { label: t('footer.links.siteName.currentOffer'), href: ROUTES.PROJECTS },
      { label: t('footer.links.siteName.about'), href: ROUTES.ABOUT },
      { label: t('footer.links.siteName.howItWorks'), href: ROUTES.HOW_IT_WORKS },
      { label: t('footer.links.siteName.scoring'), href: ROUTES.SCORING },
      { label: t('footer.links.siteName.contact'), href: ROUTES.CONTACT },
    ],
  },
  {
    title: t('footer.education'),
    items: [
      { label: t('footer.links.education.dictionary'), href: ROUTES.DICTIONARY },
      // { label: t('footer.links.education.companyIssue'), href: ROUTES.COMPANY_ISSUE },
      // { label: t('footer.links.education.czechIssue'), href: ROUTES.CZECH_ISSUE },
      // { label: t('footer.links.education.czechRepublicIssue'), href: ROUTES.CZECH_REPUBLIC_ISSUE },
      // { label: t('footer.links.education.corporateIssue'), href: ROUTES.CORPORATE_ISSUE },
      // { label: t('footer.links.education.antiInflationIssue'), href: ROUTES.ANTI_INFLATION_ISSUE },
      // { label: t('footer.links.education.savingBonds'), href: ROUTES.SAVING_BONDS },
    ],
  },
  // {
  //   items: [
  //     { label: t('footer.links.education.stateIssue'), href: ROUTES.STATE_ISSUE },
  //     { label: t('footer.links.education.investmentToIssue'), href: ROUTES.INVESTMENT_TO_ISSUE },
  //     { label: t('footer.links.education.securedBonds'), href: ROUTES.SECURED_BONDS },
  //     { label: t('footer.links.education.comparisonOfSavingAccounts'), href: ROUTES.COMPARISON_OF_SAVING_ACCOUNTS },
  //     { label: t('footer.links.education.whereToInvest'), href: ROUTES.WHERE_TO_INVEST },
  //     { label: t('footer.links.education.investmentCalculator'), href: ROUTES.INVESTMENT_CALCULATOR },
  //     { label: t('footer.links.education.mostProfitableInvestments'), href: ROUTES.MOST_PROFITABLE_INVESTMENTS },
  //   ],
  // },
  {
    title: t('footer.forIssuer'),
    items: [
      { label: t('footer.links.forIssuer.forIssuer'), href: ROUTES.FOR_ISSUERS },
      { label: t('footer.links.forIssuer.projectProposalForm'), href: '/formular-pro-navrh-projektu', target: '_blank' },
      { label: t('footer.links.forIssuer.physicalEntityRegistrationForm'), href: '/registracni-formular-fyzicke-osoby', target: '_blank' },
      { label: t('footer.links.forIssuer.legalEntityRegistrationForm'), href: '/registracni-formular-pravnicke-osoby', target: '_blank' },
    ],
  },
  {
    title: t('footer.furtherInformations'),
    items: [
      { label: t('footer.links.furtherInformations.determinationOfQualifiedInvestor'), href: '/urceni-kvalifikovaneho-investora', target: '_blank' },
      { label: t('footer.links.furtherInformations.conflictOfInterest'), href: ROUTES.CONFLICT_OF_INTEREST },
      { label: t('footer.links.furtherInformations.statistic'), href: ROUTES.STATISTICS },
      { label: t('footer.links.furtherInformations.firmsInCfg'), href: ROUTES.CFG_FIRMS },
      { label: t('footer.links.furtherInformations.lessonsForConsumers'), href: '/pouceni-pro-spotrebitele', target: '_blank' },
    ],
  },
  {
    items: [
      { label: t('footer.links.furtherInformations.clientInformations'), href: '/informace-pro-klienty', target: '_blank' },
      { label: t('footer.links.furtherInformations.complaintsProcedure'), href: '/reklamacni-rad', target: '_blank' },
      { label: t('footer.links.furtherInformations.platformUseAgreement'), href: '/smlouva-o-uzivani-platformy', target: '_blank' },
    ],
  },
])

type FooterSocialItem = {
  src: string
  href: string
}
const iconPath = computed(() => ['dev', 'production'].includes(config.public.environment) ? '/public/icons' : '/icons')
const footerSocialItems = computed<FooterSocialItem[]>(() => [
  { src: `${iconPath.value}/facebook-filled.svg`, href: 'https://www.facebook.com/Dluhopisomat.cz' },
  { src: `${iconPath.value}/linkedin-filled.svg`, href: 'https://www.linkedin.com/company/dluhopisomat' },
  { src: `${iconPath.value}/youtube-filled.svg`, href: 'https://www.youtube.com/channel/UCys53WL95dMCJj5IF1ZJpIQ?ybp=-gEIMjAyNTAyMjU%253D' },
])

type FooterLegalItem = {
  label: string
  href: string
  className?: string
  action?: () => void
  target?: string
}

const footerLegalItems = computed<FooterLegalItem[]>(() => [
  {
    label: t('footer.zoou'),
    href: '/zasady-zpracovani-osobnich-udaju',
    target: '_blank',
  },
  {
    label: t('footer.vop'),
    href: '/vop',
    target: '_blank',
  },
  {
    label: t('footer.cookies'),
    href: 'javascript: Cookiebot.renew()',
    className: 'cky-banner-element',
  },
])
</script>

<template>
  <footer>
    <div class="container grid grid-cols-1 gap-x-8 gap-y-5 p-5 md:p-0 md:pt-20 lg:grid-cols-5">
      <div>
        <AppLogo class="h-[40px] w-[169px]" />

        <img class="mt-2 text-[170px] md:mt-6" src="public/cnb-logo.svg">

        <CfgTypography class="mt-2 flex flex-col gap-y-3 md:mt-6" :size="CfgTypographySizes.md">
          <a :href="`tel:${supportPhone.replace(/ /g, '')}`"> {{ supportPhone }} </a>

          <a :href="`mailto:${EMAIL}`"> {{ EMAIL }} </a>
        </CfgTypography>
      </div>

      <div class="col-span-3 grid grid-cols-1 gap-x-8 max-md:mt-4 md:grid-cols-3">
        <div
          v-for="group in footerItemGroups"
          :key="group.title"
          class="col-span-1"
        >
          <CfgTypography
            v-if="group.title"
            class="block !font-highlighted text-grey max-md:mt-10 md:!h-14"
            :size="CfgTypographySizes.h5"
          >
            {{ group.title }}
          </CfgTypography>

          <ul
            class="space-y-4 md:space-y-5"
            :class="{
              'max-md:mt-4 md:mt-14': !group.title,
              'max-md:mt-5 md:mb-8': group.title,
            }"
          >
            <li v-for="item in group.items" :key="item.label">
              <a :href="item.href" :target="item.target ? item.target : '_self'">
                <CfgTypography>
                  {{ item.label }}
                </CfgTypography>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="flex items-start gap-x-3 max-md:mt-8 md:justify-end">
        <a
          v-for="(item, index) in footerSocialItems"
          :key="index"
          class="flex size-8 items-center justify-center rounded-full bg-orange"
          :href="item.href"
          target="_blank"
        >
          <img
            :src="item.src"
            style="--stroke-width:0"
          >
        </a>
      </div>

      <div class="col-span-full mt-10 flex justify-end gap-2 border-t-2 border-grey-50 pt-6 max-md:flex-col md:items-center md:gap-12">
        <CfgTypography
          class="mr-auto"
          :size="CfgTypographySizes.sm"
        >
          {{ t('footer.copyright', {
            year: new Date().getFullYear(),
          }) }}
        </CfgTypography>

        <a
          v-for="item in footerLegalItems"
          :key="item.label"
          class="underline"
          :class="item.className"
          :href="item.href"
          :target="item.target ? item.target : '_self'"
        >
          <CfgTypography
            :size="CfgTypographySizes.sm"
          >
            {{ item.label }}
          </CfgTypography>
        </a>
      </div>

      <div class="col-span-full mt-6 border-t-2 border-grey-50 pb-12 pt-6">
        <CfgTypography class="col-span-full" :size="CfgTypographySizes.sm">
          {{ $t('footer.legal') }}
        </CfgTypography>
      </div>
    </div>
  </footer>
</template>
